/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).attr('target', '_blank');

        // Navigation
        $('.js-toggle-nav').click(function (e) {
          e.preventDefault();

          $('body').toggleClass('nav-is-visible');
        });

        // Dropdown
        $('.js-toggle-dropdown').click(function (e) {
          e.preventDefault();

          $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
        });

        // Initiate lazy load
        //new LazyLoad();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var viewportWidth = $(window).width();
        var maxViewport = 1024;

        // Carousel(s)
        if ($('.js-carousel-testimonials').length) {
          $('.js-carousel-testimonials').slick({
            adaptiveHeight: true,
            autoplay: true,
            arrows: true,
            prevArrow: '<button class="slick-prev slick-arrow"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow"><i class="fas fa-chevron-right"></i></button>',
            dots: false
          });
        }

        if ($('.js-carousel-hero').length) {
          $('.js-carousel-hero').slick({
            autoplay: true,
            arrows: false,
            dots: false
          });
        }

        if ($('.js-property-gallery').length) {
          $('.js-property-gallery').slick({
            arrows: true,
            asNavFor: '.js-property-gallery-nav',
            dots: false,
            fade: true,
            nextArrow: '<button type="button" class="slick-prev" aria-label="Previous Gallery Item"><i class="fas fa-chevron-left"></i></button>',
            prevArrow: '<button type="button" class="slick-next" aria-label="Next Gallery Item"><i class="fas fa-chevron-right"></i></button>',
            rows: 0,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        if ($('.js-property-gallery-nav').length) {
          $('.js-property-gallery-nav').slick({
            arrows: false,
            asNavFor: '.js-property-gallery',
            autoplay: true,
            dots: false,
            focusOnSelect: true,
            rows: 0,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
          });
        }

        // Hero Background(s)
        var heroItem = $('.js-background-hero');

        heroItem.each(function () {
          var mobileBG = $(this).data('mobile');
          var desktopBG = $(this).data('desktop');

          if (viewportWidth < maxViewport) {
            $(this).css('background-image', 'url(' + mobileBG + ')');
          } else {
            $(this).css('background-image', 'url(' + desktopBG + ')');
          }
        });

        // Google Map
        var map,
          geocoder = new google.maps.Geocoder(),
          infowindow = new google.maps.InfoWindow(),
          coordinates = [42.798577, -72.5160514,];

        function initialize() {
          var latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);

          var map = new google.maps.Map(document.getElementById('map'), {
            disableDefaultUI: true,
            draggable: true,
            scrollwheel: false,
            zoom: 15,
            zoomControl: true,
            center: latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });

          geocoder.geocode({ "latLng": latLng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              google.maps.event.addDomListener(window, 'resize', function () {
                map.setCenter(results[0].geometry.location);
              });

              var marker = new google.maps.Marker({
                map: map,
                position: results[0].geometry.location
              });
            }
          });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
